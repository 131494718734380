<template>
  <div v-if="isLoading">
    <aom-skeleton-loader />
  </div>
  <validation-observer
    v-else
    ref="observer"
    v-slot="{ invalid, handleSubmit }"
  >
    <section
      v-if="currentQuestion"
    >
      <b-card>
        <h3 class="text-capitalize mb-2">
          Edit {{ roleAlias }} Application
        </h3>
        <template v-if="currentQuestion.type_id === modelQuestionTypes.TEXT">
          <aom-application-single-text-box
            :question.sync="currentQuestion"
            :preferred-language-id="preferredLanguageId"
          />
        </template>
        <template v-if="currentQuestion.type_id === modelQuestionTypes.CHOICE">
          <aom-application-choice
            :question.sync="currentQuestion"
          />
        </template>
        <template v-if="currentQuestion.type_id === modelQuestionTypes.MATCHING">
          <aom-application-multi-choice-matching
            :question.sync="currentQuestion"
          />
        </template>
        <template v-if="currentQuestion.type_id === modelQuestionTypes.SCALE">
          <aom-application-rating
            :question.sync="currentQuestion"
          />
        </template>
        <b-row>
          <div class="col-md-3 mb-3 col-left">
            <b-button
              block
              variant="outline-secondary"
              @click="handleCloseForm"
            >
              Cancel
            </b-button>
          </div>
          <div class="col-md-3 offset-md-6 mb-3 col-right">
            <b-button
              block
              variant="primary"
              :disabled="invalid"
              @click="handleSubmit(saveAnswer)"
            >
              <b-spinner
                v-if="isSumitting"
                small
              />
              Save
            </b-button>
          </div>
        </b-row>
      </b-card>
    </section>
  </validation-observer>
</template>

<script>
import { mapGetters } from "vuex";
import {
  BRow,
  BButton,
  BSpinner,
  BCard,
} from "bootstrap-vue";
import { questionTypes as modelQuestionTypes } from '@/models/questionTypes';
import { ValidationObserver } from "vee-validate";
import { makeErrorToast } from "@/libs/utils";
import AomSkeletonLoader from "@aom-core/AomSkeletonLoader.vue";
import { userRoles, userRolesDisplay } from '@models';
import AomApplicationSingleTextBox from '@aom-core/AomApplicationSingleTextBox.vue';
import AomApplicationChoice from '@aom-core/AomApplicationChoice.vue';
import AomApplicationMultiChoiceMatching from '@aom-core/AomApplicationMultiChoiceMatching.vue';
import AomApplicationRating from '@aom-core/AomApplicationRating.vue';
import {
  getValidationState
} from "@/libs/utils";
export default {
  name: "ApplicationEdit",
  components: {
    BRow,
    BButton,
    BSpinner,
    AomSkeletonLoader,
    ValidationObserver,
    AomApplicationSingleTextBox,
    AomApplicationChoice,
    AomApplicationMultiChoiceMatching,
    AomApplicationRating, 
    BCard
  },
  data() {
    return {
      messageError: null,
      messageErrorOther: null,
      newAnswers: [],
      modelQuestionTypes: modelQuestionTypes,
      ownApplication: null,
      isLoading: false,
      isSumitting: false
    };
  },


  computed: {
    ...mapGetters("app", {
      appCurrentProgram: "currentProgram",
      isParticipantPortal: "isParticipantPortal"
    }),
    ...mapGetters("profile", ["profile", "preferredLanguageId"]),
    ...mapGetters("participants", ["answerData", 'completedPercentMentee', 'completedPercentMentor']),
    applicationSetId() {
      const program = this.$store.getters['participants/program'];
      if (program && program.application_set) {
        return program.application_set.id;
      }
      return null;
    },
    currentQuestion() {
      if (!this.answerData) {
        return null;
      }
      const questionId = this.$route.params.questionId;
      if (!questionId) {
        return null;
      }
      return this.answerData.questions.find(
        question => question.id === Number(questionId)
      );
    },
    pageHeader() {
      return 'Edit Application';
    },
    roleNameFromRoute () {
      return Number(this.$route.params.role);
    },
    questionNumber () {
      return this.answerData.questions.findIndex(q => q.id === this.currentQuestion.id) + 1;
    },
    roleAlias () {
      return this.appCurrentProgram.program_roles.find(r => r.role_id === this.roleNameFromRoute)?.alternative_role_name || userRolesDisplay[this.roleNameFromRoute];
    },
    questionTypeId () {
      return this.currentQuestion.type_id;
    }
  },

  created() {
    this.loadApplication();
  },

  methods: {
    async loadApplication() {
      try {
        this.isLoading = true;

        if (this.isParticipantPortal &&
          this.appCurrentProgram &&
          this.appCurrentProgram.id &&
          this.applicationSetId
        ) {
          this.ownApplication = await this.$store.dispatch("participants/FETCH_OWN_APPLICATION_SETS", {
            programId: this.appCurrentProgram.id,
            applicationSetId: this.applicationSetId,
            role: this.roleNameFromRoute
          });

          if (this.ownApplication) {
            await this.$store.dispatch("participants/FETCH_OWN_APPLICATION_ANSWERS", {
              programId: this.appCurrentProgram.id,
              formId: this.ownApplication.id,
              userId: this.profile.id
            });
          }
        }
      } catch (e) {
        this.$log.error(e);
        this.$toast(makeErrorToast(e));
      } finally {
        this.isLoading = false;
      }
    },
    makeRequestParams() {
      const params = {};
      if (this.appCurrentProgram && this.appCurrentProgram.id) {
        params.programId = this.appCurrentProgram.id;
      }
      if (this.ownApplication) {
        params.formId = this.ownApplication.id;
      }
      params.userId = this.profile.id;
      return params;
    },

    getAnswerData() {
      if(this.questionTypeId === modelQuestionTypes.SCALE) {
        return { answers: this.currentQuestion.statements.filter(statement => statement.answers[0]).map(statement => statement.answers[0])};
      }
      return { answers: this.currentQuestion.answers };
    },
    async saveAnswer() {
      try {

        this.isSumitting = true;
        const params = this.makeRequestParams();
        const data = this.getAnswerData();
        let hasAnswer = true;
        let isSuccess = false;
        if (Array.isArray(data.answers) && !data.answers.length) {
          hasAnswer = false;
        }
        if (hasAnswer) {
          isSuccess = await this.$store.dispatch("participants/SAVE_OWN_APPLICATION_ANSWERS", {
            params,
            data
          });
        }
        

        if (isSuccess || !hasAnswer) {
          this.checkApplicationComplete();
        }
      } catch (e) {
        console.log(e);
        this.$toast(makeErrorToast("Something went wrong. Answer not saved."));
        this.$log.error(e);
      } finally {
        this.isSumitting = false;
      }
    },
    checkApplicationComplete() {
      const applicationRoleMentee = this.ownApplication.roles.filter(r =>  r.id === userRoles.MENTEE);
      const applicationRoleMentor = this.ownApplication.roles.filter(r =>  r.id === userRoles.MENTOR);

      if(applicationRoleMentee.length > 0 && this.completedPercentMentee !== 100) {
        this.showCompletePopUp();
        return;
      }
      if(applicationRoleMentor.length > 0 && this.completedPercentMentor !== 100) {
        this.showCompletePopUp();
        return;
      }
      this.handleCloseForm();

    },
    handleCloseForm() {
      const routeName = Number(this.$route.params.role) === Number(userRoles.MENTOR) ? 'participant-application-mentor-overview' : 'participant-application-mentee-overview';
      this.$router.push({ name: routeName });
    },
    showCompletePopUp() {
      this.$bvModal
        .msgBoxConfirm("Your application is not complete! Would you like to continue and complete your application?", {
          title: 'Complete Application',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if(!value) {
            this.handleCloseForm();
          }
          if(value) {
            this.routeToLowestUnAnsweredQuestion();           
          }
        });
    },
    firstUnanswerQuestionNonScale() {
      return this.answerData.questions.find(q => q.answers.length === 0 && q.type_id !== modelQuestionTypes.SCALE && q.id !== this.$route.params.questionId);
    },
    lowestRatingScaleQNoAnswer() {
      const ratingQ = this.answerData?.questions?.filter(q => q.type_id === modelQuestionTypes.SCALE);            
      const answeredRatingQ = ratingQ?.filter(q => q?.statements.find(s => s?.answers && s?.answers.length === 0));
      if(answeredRatingQ && answeredRatingQ.length > 0) {
        return answeredRatingQ.reduce((a, b) => a.question_order < b.question_order ? a : b);
      } 
    },
    routeToLowestUnAnsweredQuestion() {
      
      const lowestQuestionOrder = this.lowestRatingScaleQNoAnswer();
      const firstUnanswerQuestionNonScale = this.firstUnanswerQuestionNonScale();
      const routeName = Number(this.$route.params.role) === Number(userRoles.MENTOR) ? 'participant-application-mentor' : 'participant-application-mentee';
      if(lowestQuestionOrder && firstUnanswerQuestionNonScale) {
        const { id } = [lowestQuestionOrder, firstUnanswerQuestionNonScale].reduce((a, b) => a?.question_order < b?.question_order ? a : b);
        this.$router.push({ name: routeName, params: {questionId: id, role: this.$route.params.role}});
      }
      if(!lowestQuestionOrder && firstUnanswerQuestionNonScale) {
        const { id } = firstUnanswerQuestionNonScale; 
        this.$router.push({ name: routeName, params: {questionId: id, role: this.$route.params.role}});
      }
      if(lowestQuestionOrder && !firstUnanswerQuestionNonScale) {
        const { id } = lowestQuestionOrder; 
        this.$router.push({ name: routeName, params: {questionId: id, role: this.$route.params.role}});
      }
    }
  },
  setup() {
    return {
      getValidationState
    };
  }
};
</script>