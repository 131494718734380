<template>
  <section>
    <validation-provider
      v-slot="validationContext"
      :ref="`matching-choice-${question.id}`"
      rules="required"
      :vid="`matching-choice-${question.id}`"
      name="Matching Choice"
    >
      <b-card no-body>
        <b-card-header
          header-text-variant="dark"
          header-bg-variant="light-primary"
        >
          <b-row class="full-width">
            <b-col
              sm="8"
            >
              {{ questionNumber }}.  <span v-html="linkifyStr(questionHeader, linkOptions)">{{ questionHeader }}</span>
              <p class="text-muted">
                <span class="vibility-hidden">{{ questionNumber }}.</span> {{ getTranslationTextByUserLocale(question.translations, 'prefilled_answer') }}
              </p>
            </b-col>
            <b-col
              sm="4"
              class="d-flex justify-content-end"
            >
              <span
                v-if="question.is_visible"
                class="d-flex align-items-center"
              >
                <feather-icon
                  class="mr-50"
                  icon="EyeIcon"
                  size="16"
                /><span class="d-none d-sm-inline-block">Visible to matched partner</span>
              </span>
              <div class="d-flex flex-column align-items-center">
                <b-link 
                  v-if="readOnly && allowEdit"  
                  class="ml-50 mr-50 mb-50"
                  @click="$emit('edit', question)"
                >
                  <feather-icon
                    class="mr-50"
                    icon="EditIcon"
                    size="16"
                  /><span class="d-none d-sm-inline-block">Edit</span>
                </b-link>
                <b-badge
                  v-if="question.is_required"
                  class="ml-1 mr-1"
                  variant="outline-danger"
                  size="26"
                  style="margin-top: -3px"
                >
                  Required
                </b-badge>
              </div>
            </b-col>
          </b-row>  
        </b-card-header>
        <b-card-body body-border-variant="light">
          <b-row>
            <b-col
              md="12"
              class="mt-2 pl-3 pr-3 pb-2"
            >
              <b-form-checkbox-group
                v-if="isMultiAnswer"
                v-model="selected"
                stacked
                :options="options"
                tabindex="0"
                :disabled="readOnly"
              />
              <b-form-radio-group
                v-if="!isMultiAnswer"
                v-model="selected"
                stacked
                :options="options"
                tabindex="0"
                :disabled="readOnly"
              />
              <b-form-invalid-feedback
                :state="
                  getValidationState(
                    validationContext
                  )
                "
              >
                {{
                  validationContext.errors[0]
                }}
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </validation-provider>
  </section>
</template>
      
<script>
import { ValidationProvider } from "vee-validate";
import { 
  BFormInvalidFeedback,
  BRow,
  BCol,
  BFormCheckboxGroup,
  BFormRadioGroup,
  BCard, 
  BCardHeader,
  BCardBody,
  BLink,
  BBadge
} from "bootstrap-vue";
import { getValidationState } from "@/libs/utils";
import { required } from "@validations";
import _isEqual from 'lodash/isEqual';
import { locales } from '@models';
import linkifyStr from 'linkify-string';

export default {
  name: "AoMApplicationMultiChoiceMatching",
  components: {
    BRow,
    BCol,
    ValidationProvider,
    BFormInvalidFeedback, 
    BFormCheckboxGroup,
    BFormRadioGroup,
    BCard,
    BCardHeader,
    BCardBody,
    BLink,
    BBadge
  },
      
  props: {
    question: {
      type: Object,
      required,
      default: () => {}
    },
    index: {
      type: Number,
      default: 0,
      required
    },
    showMatchingWeight: {
      type: Boolean,
      default: true
    },
    preferredLanguageId: {
      type: Number,
      default: locales.EN
    }, 
    readOnly: {
      type: Boolean
    },
    allowEdit: {
      type: Boolean,
      default: true
    }
  },
      
  data() {
    return {
      selected: [],
      options: [],
      payload: [],
      linkOptions: {
        className: 'external_link',
        target: '_blank',
      }
    };
  },
  computed: {
    isMultiAnswer() {
      return Number(this.question.choice_limit) >  Number(1);
    },
    answerChoices() {
      return this.question.choices;
    },
    questionHeader() {
      const trans = this.getTranslationTextByUserLocale(this.question.translations,'question_text');
      return `${trans}`;
    },
    questionNumber() {
      return Number(this.index || this.question.question_order) + 1;
    }
  },
  watch: {
    selected(n, o) {
      if(_isEqual(n, o) || n === undefined) {
        return;
      }
      this.setOptionsDisabled();
      this.handlePayload();
    },
    payload() {
      this.$emit("input", this.payload);
    },
    question: {
      handler(n, o) {
        if(_isEqual(n, o)) {
          return;
        }
        if(n) {
          this.setQuestionOptions();
          this.setOptionsDisabled();
           
          if(this.choiceOther) {
            const otherAnswer = this.question.answers.find(
              answer => answer.choice_id === this.choiceOther.id
            );
            if (otherAnswer) {
              this.answerTextOther = otherAnswer.answer_text;
            } else {
              if(this.choiceOther) {
                this.otherPlaceHolderText = this.getTranslationTextByUserLocale(
                  this.choiceOther.translations,
                  "choice_text"
                );
              }
            }
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    setOptionsDisabled () {
      if(this.selected && this.selected.length === Number(this.question.choice_limit)) {
        this.options = this.answerChoices.map(o => {
          if(!this.selected.includes(o.id)) {
            return { text: this.getTranslationTextByUserLocale(
              o.translations,
              "choice_text"
            ), 
            value: o.id, 
            disabled: true 
            };
          } else {
            return { text: this.getTranslationTextByUserLocale(
              o.translations,
              "choice_text"
            ), 
            value: o.id, 
            disabled: false 
            };
          }
        });
      } else {
        this.options = this.answerChoices.map(o => ({ text: this.getTranslationTextByUserLocale(
          o.translations,
          "choice_text"
        ), value: o.id, disabled: false }));
      }
    },
    getTranslationTextByUserLocale(translations, propertyName) {
      return this.$store.getters["participants/getTranslationTextByUserLocale"](
        translations,
        propertyName,
        this.preferredLanguageId
      );
    },
    setQuestionOptions () {
      if(this.isMultiAnswer) {
        this.selected = this.question.answers.map(
          answer => answer.choice_id
        );
      } else {
        this.selected = this.question.answers.map(
          answer => answer.choice_id
        ).shift();
      }
    },
    handlePayload() {
      if(this.isMultiAnswer) {
        this.question.answers = this.selected.map(select =>
          this.choiceOther && select === this.choiceOther.id
            ? {
              question_id: this.question.id,
              choice_id: select,
              answer_text: this.answerTextOther,
            }
            : {
              question_id: this.question.id,
              choice_id: select,
            }
        );
      } else {
        // Single Answer
        this.question.answers = [
          this.choiceOther && this.selected === this.choiceOther.id
            ? {
              question_id: this.question.id,
              choice_id: this.selected,
              answer_text: this.answerTextOther
            }
            : {
              question_id: this.question.id,
              choice_id: this.selected
            }];
      }
        
    }
  },
  setup() {
    return {
      getValidationState,
      linkifyStr
    };
  }
};
</script>
<style lang="scss" scoped>
.margin-left-18 {
  margin-left: 18px;
}
.vibility-hidden {
  visibility: hidden;
}
</style>
      